import { Result, ApiResp } from "../../common";
import { ReqType } from "../../utils";
import { HttpRequestor, Method } from "../../adapter";
import { BusinessType } from "../models";

interface MetaKey {
    id: number;
    encryptedMetaKey: string;
    businessType: BusinessType;
    ownerId: number;
}

export interface ShareKeyInfo {
    id: number;
    vnoId: number;
    metaKeys: MetaKey[];
}

export interface Form {
    token: string;
    userId: number;
    brandId: number;
    hashedShareKey: string;
}

export async function getBrandShareKey(httpRequest: HttpRequestor, keyServerBase: string, form: Form): Promise<Result<ApiResp<ShareKeyInfo | undefined>, string>> {
    let headers: {
        [header: string]: string | string[];
    } = {
        token: form.token,
        operatorId: '' + form.userId
    };
    return httpRequest<{
        brandId: number;
        hashedShareKey: string;
    }, ApiResp<ShareKeyInfo | undefined>, 'json'>({
        method: Method.Post,
        url: keyServerBase + "/api/web/account/key/public/brand/sharekey/get/v1",
        data: ReqType.Json({
            brandId: form.brandId,
            hashedShareKey: form.hashedShareKey,
        }),
        headers: headers,
        respType: "json"
    });
}
